<template>
  <div class="create_wrapper fadeIn">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between product">
            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.name"
                  @keypress="isArabic($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name_ar") }}
                </label>
              </div>
            </div> -->

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.name"
                  @keypress="isEnglish($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name_en") }}
                </label>
              </div>
            </div> -->

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <ckeditor v-model="data.ar.desc" />
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_ar") }}
                </label>
               
              </div>
            </div> -->

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <ckeditor v-model="data.en.desc" />
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_en") }}
                </label>
               
              </div>
            </div> -->
            <!-- Start:: Start Date -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_date") }}
                </label>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.start_date"
                    @input="dateMenu = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!-- End:: Start Date -->

            <!-- Start:: End Date -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_date") }}
                </label>
                <v-menu
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.end_date"
                    @input="dateMenu2 = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <!-- Product images -->
            <div class="col-12">
              <div class="row productDetails mb-2">
                <h4>{{ $t("forms.labels.productImages") }}</h4>
                <div
                  class="col-lg-6"
                  v-for="(image, index) in data.images"
                  :key="image.uuid"
                >
                  <div class="row">
                    <div class="col-11">
                      <uplode-image
                        @inputChanged="uplodeProductImage($event, index)"
                        :data_src="image.image"
                        :placeHolder="$t('forms.labels.image')"
                      ></uplode-image>
                      <div>
                        <div class="input_wrapper top_label">
                          <input
                            @keypress="preventMinus"
                            type="number"
                            class="form-control"
                            @input="helper_checkIfInputIsEmpty"
                            v-model.trim="image.ordering"
                          />
                          <label for="name_input" class="form-label">
                            {{ $t("forms.labels.ordering") }}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-1 py-0 d-flex justify-center align-center flex-column"
                    >
                      <span
                        v-if="index == data.images.length - 1"
                        class="append mx-1"
                        @click="appendImageRow"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </span>

                      <span
                        v-if="data.images.length > 1"
                        class="append mx-1"
                        @click="deleteImageRow(index, image)"
                      >
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Product images -->

            <!-- Start:: Is Active -->
            <div class="col-lg-4 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="#FF4300"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Create",
  props: ["id"],

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.magazine.title"),
          disabled: false,
          href: "/magazine/show-all",
        },
        {
          text: this.$t("breadcrumb.magazine.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      rootCategory: null,
      // ========== create_data
      dateMenu: false,
      dateMenu2: false,
      start_time_modal: false,
      end_time_modal: false,

      data: {
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        ar: {
          name: null,
          desc: null,
        },
        en: {
          name: null,
          desc: null,
        },
        category: null,
        code: null,
        price: null,
        points: null,
        brand: null,
        type: null,
        percentage: null,
        product_details: [
          {
            quantity: null,
            price: null,
            color: null,
            size: null,
            features: [
              {
                feature: null,
                value: null,
                values: [],
              },
            ],
            media: [
              {
                image: null,
              },
            ],
          },
        ],
        images: [
          {
            uuid: (((1 + Math.random()) * 0x10000) | 0)
              .toString(16)
              .substring(1),
            image: null,
            ordering: null,
          },
        ],

        branches: [
          {
            id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
            branch: null,
            quantity: null,
          },
        ],

        selected_ids: [],
        size_guide: null,
        is_active: false,
        // is_replace: true,
        ordering: null,
        is_check_all_branch: false,
        branches_quantity: null,
      },
      selectAddType: true,

      // Fixed Data
      categories: [],
      rootsCategories: [],

      colors: [],
      sizes: [],
      features: [],
      brands: [],
      allBranches: [],
      branches: [],
      types: [
        {
          name: this.$t("titles.topDeals"),
          id: "top_deals",
        },
        {
          name: this.$t("titles.sales"),
          id: "sales",
        },
        {
          name: this.$t("titles.news"),
          id: "news",
        },
      ],
    };
  },

  computed: {
    product_details() {
      return this.data.product_details;
    },
  },

  methods: {
    //add Row
    addBranch() {
      this.data.branches.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        branch: null,
        quantity: null,
      });
    },
    //remove ow
    removeBranch(index) {
      this.data.branches.splice(index, 1);
      this.getCurrentBranches();
    },
    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      // if (!this.data.ar.name) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.name_ar"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // } else if (!this.data.en.name) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.name_en"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;

      //   return;
      // } else if (!this.data.ar.desc) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.ar.desc"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // } else if (!this.data.en.desc) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.en.desc"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // }

      let validate = true;

      this.data.images.map((el, index) => {
        if (!el.image) {
          this.$iziToast.error({
            timeout: 2000,
            message:
              this.$t("forms.validation.image") +
              " " +
              "( " +
              (index + 1) +
              " )",
          });
          this.btnIsLoading = false;
          validate = false;
        } else if (el.ordering === null) {
          this.$iziToast.error({
            timeout: 2000,
            message:
              this.$t("forms.validation.ordering") +
              " " +
              "( " +
              (index + 1) +
              " )",
          });
          this.btnIsLoading = false;
          validate = false;
        }
      });
      if (!validate) return;

      this.submitData();
    },

    resetAll() {
      this.data.product_details.forEach((item) => {
        item.color = null;
        item.size = null;
        item.features = [
          {
            feature: null,
            value: null,
            values: [],
          },
        ];
      });
    },

    filterFeat(id) {
      this.features = this.features.filter((feat) => {
        return feat.id != id;
      });
    },

    setFeatuerValues(detail_index, feat_index, feet_id) {
      // this.filterFeat(feet_id);

      let detailRow = this.data.product_details[detail_index];
      let featRow = detailRow.features[feat_index];

      let current_values = featRow.feature.values;
      featRow.values = current_values.map((item) => {
        return {
          id: item.id,
          value: `${item.value}`,
        };
      });
    },

    appendDetailRow() {
      this.data.product_details.push({
        quantity: null,
        price: null,
        color: null,
        size: null,
        features: [
          {
            feature: null,
            value: null,
            values: [],
          },
        ],
        media: [
          {
            image: null,
          },
        ],
      });
    },
    deleteDetailRow(index, detail_id) {
      if (detail_id) {
        this.$axios({
          method: "DELETE",
          url: `products/${this.id}/details/${detail_id}`,
        }).then(() => {
          this.data.product_details.splice(index, 1);
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        });
      } else {
        this.data.product_details.splice(index, 1);
      }
    },
    appendImageRow() {
      this.data.images.push({
        uuid: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        image: null,
        ordering: null,
      });
    },
    async deleteImageRow(index, image) {
      if (image.id) {
        await this.$axios
          .delete(`magazine/image/${image.id}`)
          .then((res) => {
            console.log(res);
            this.$iziToast.success({
              timeout: 2000,
              message: res.data.messages,
              position: "bottomRight",
            });

            this.data.images.splice(index, 1);
          })
          .catch((err) => {
            console.log(err);
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          });
      } else {
        this.data.images.splice(index, 1);
      }
    },

    appendFeatuer(detail_index) {
      this.data.product_details[detail_index].features.push({
        feature: null,
        value: null,
        values: [],
      });
    },
    deleteFeatuer(detail_index, feat_index) {
      this.data.product_details[detail_index].features.splice(feat_index, 1);
    },

    appendImg(detail_index) {
      this.data.product_details[detail_index].media.push({
        image: null,
      });
    },
    deleteImg(detail_index, img_index, img_id) {
      if (img_id) {
        this.$axios({
          method: "DELETE",
          // url: `products/${this.id}/details/images/${img_id}`,
          url: `products/images/${img_id}`,
        }).then(() => {
          this.data.product_details[detail_index].media.splice(img_index, 1);

          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        });
      } else {
        this.data.product_details[detail_index].media.splice(img_index, 1);
      }
    },

    // Uplode Image
    uplodeImg(obj, detail_index, imgIndex) {
      this.data.product_details[detail_index].media[imgIndex].image = obj;
    },
    uplodeSizeGuide(obj) {
      this.data.size_guide = obj.img_file;
    },

    getBrands() {
      this.$axios({
        method: "GET",
        url: `get_all_brand`,
      }).then((res) => {
        this.brands = res.data.data;
      });
    },

    getBranches() {
      this.$axios({
        method: "GET",
        url: `get_all_branch`,
      }).then((res) => {
        this.allBranches = res.data.data.map((x) => {
          return {
            id: x.id,
            name: x.full_name,
          };
        });
        this.branches = this.allBranches;
      });
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      // submit_data.append("ar[name]", this.data.ar.name);
      // submit_data.append("ar[desc]", this.data.ar.desc);

      // submit_data.append("en[name]", this.data.en.name);
      // submit_data.append("en[desc]", this.data.en.desc);

      if (this.data.start_date) {
        submit_data.append("start_at", `${this.data.start_date} `);
      }
      if (this.data.end_date) {
        submit_data.append("end_at", `${this.data.end_date} `);
      }

      submit_data.append("is_active", +this.data.is_active);
      submit_data.append("_method", "PUT");

      this.data.images.map((image, index) => {
        if (image.image?.img_file) {
          submit_data.append(
            `magazine_images[${index}][image]`,
            image.image?.img_file
          );
          submit_data.append(
            `magazine_images[${index}][ordering]`,
            image.ordering
          );
          if (image.id) {
            submit_data.append(`magazine_images[${index}][image_id]`, image.id);
          }
        }
      });
      this.$axios({
        method: "POST",
        url: `magazine/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/magazine/show-all" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message ?? err.response.data.messages,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },

    // ====== Sub Categories

    appendCopyDetailRow(row, index) {
      let deepCopy = _.cloneDeep(row);

      this.data.product_details.splice(index + 1, 0, deepCopy);
    },
    uplodeProductImage(image, index) {
      this.data.images[index].image = image;
    },
    getData() {
      this.$axios.get(`magazine/${this.id}`).then((res) => {
        console.log(res.data);
        this.data.end_date = res.data.data.end_at;
        this.data.start_date = res.data.data.start_at;
        this.data.images = res.data.data.media;
        this.data.is_active = res.data.data.is_active;
      });
    },
  },

  mounted() {
    this.getData();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.product {
  .chooseImage {
    width: auto !important;
  }
}

.productDetails {
  border: 1px solid #339989;
  border-radius: 15px;
  padding-block: 20px;

  &.disabled {
    cursor: not-allowed;

    h3 {
      color: gray;
      font-size: 25px;
    }

    h4 {
      font-size: 21px;
      color: gray;
    }

    & > * {
      pointer-events: none;
    }
  }
}
</style>
